import React from 'react';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { isUAE } from 'utils/env';

export default function Loading() {
    const { t } = useTranslation()

    return (
        <Spin tip={t("Loading")}/>
    );
  }